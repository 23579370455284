import React from 'react';
import SidebarForm from '../SidebarForm';
import sidebarStyles from './sidebarSubscribe.module.scss';

const SidebarSubscribe = () => {
    return (
        <div className={sidebarStyles.sidebarcontainer}>
           <h3>Aboneaza-te la newsletter</h3>
           <p>Daca îți plac lucrurile pe care le-ai găsit pe aici și vrei mai mult
               atunci nu ezita să te abonezi la newsletter și să fii la curent cu ceea
               ce se întîmpla pe acest blog. Îți voi trimite săptamanal noile articole publicate
               pe blog, tutoriale scrise și video despre programare web și alte materiale despre cum
               poți reuși în acest domeniu al programarii web.
           </p>
           <p>Așa că lasă-ți numele și adresa de email în formularul de mai jos și te voi ține la curent.</p>
           <SidebarForm />
        </div>
    );
}
export default SidebarSubscribe;