import React from 'react';
import { Link } from 'gatsby';
import articleSubscribeStyles from './articlesubscribe.module.scss';

const ArticleSubscribe = () => {
    return (
        <div className={articleSubscribeStyles.articlesubscribecontainer}>
            <h2>Ți-a plăcut acest articol? Vrei mai mult?</h2>
            <p>Dacă îți plac materialele de aici și vrei să primești mai multe articole pe email sau 
                dacă vrei să fii la curent cu ceea ce se întîmplă în aceasta comunitate
                atunci nu ezita să te abonezi la newsletter.
            </p>
            <p>Îți voi trimite săptămînal materiale despre web development, web design, programare și multe altele astfel încât să reușești 
                să îți dezvolți abilitați noi care să te ajute la job sau la pasiunile tale legate de programare.
            </p>
            <Link className={articleSubscribeStyles.subscribeButton} to="/subscribe">Mă abonez</Link>
        </div>
    );
}
export default ArticleSubscribe;