import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../shared/Layout';
import CenterLayout from '../shared/CenterLayout';
import DoubleLayout from '../shared/DoubleLayout';
import ArticleSubscribe from '../components/ArticleSubscribe';
import Head from '../shared/Head';
import blogStyles from './blog.module.scss';

export const query = graphql`
    query (
        $slug: String!
    ) {
        markdownRemark (
        fields: {
            slug: {
            eq: $slug
            }
        }
        ) {
        frontmatter {
            title,
            date,
            description,
            keywords,
            socialmediaimg
        },
        html
        fields {
            slug
        }
        }
        }
`


const BlogPage = (props) => {
    const date = props.data.markdownRemark.frontmatter.date;
    const title = props.data.markdownRemark.frontmatter.title;
    const description = props.data.markdownRemark.frontmatter.description;
    const keywords = props.data.markdownRemark.frontmatter.keywords;
    const text = props.data.markdownRemark.html;
    const slug = props.data.markdownRemark.fields.slug;
    const socialmediaimg = props.data.markdownRemark.frontmatter.socialmediaimg;

    return (
        <Layout>
            <Head title={title} url={slug} description={description} keywords={keywords} socialMediaImgUrl={socialmediaimg}/>
            <DoubleLayout>
                <span className={blogStyles.date}>{date}</span>
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{__html: text}}/>
                <ArticleSubscribe />
            </DoubleLayout> 
        </Layout>
    );
}
export default BlogPage;