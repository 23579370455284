import React from 'react';
import SidebarSubscribe from '../../components/SidebarSubscribe';
import doubleLayoutStyles from './doubleLayout.module.scss';
import htmlAndCss from '../../img/basicwebdev.jpg';
import jsAndJquery from '../../img/jsAndJquery.jpg';
import fullStackReact from '../../img/fullStackReact.jpg';
import cleanCode from '../../img/cleancode.jpg';

const DoubleLayout = (props) => {
    return (
        <div className={doubleLayoutStyles.doublelayoutwrapper}>
            <div className={doubleLayoutStyles.books}>
                <h3 className={doubleLayoutStyles.bookstitle}>Cărți preferate</h3>
                <div className={doubleLayoutStyles.booksimagecontainer}>
                    <img src={htmlAndCss} alt="HTML & CSS - carte favorita"/>
                </div>
                <div className={doubleLayoutStyles.booksimagecontainer}>
                    <img src={jsAndJquery} alt="JavaScript & Jquery - carte favorita" />
                </div>
                <div className={doubleLayoutStyles.booksimagecontainer}>
                    <img src={fullStackReact} alt="Fullstack React - carte preferata"/>
                </div>
                <div className={doubleLayoutStyles.booksimagecontainer}>
                    <img src={cleanCode} alt="Clean Code - carte preferata"/>
                </div>
            </div>
            <div className={doubleLayoutStyles.content}>{props.children}</div>
            <div className={doubleLayoutStyles.sidebar}>
                <SidebarSubscribe/>
            </div>
        </div>
    );
}
export default DoubleLayout;